/**
 * 关于平台授权方面的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class NotificationApi {
	// 获取
	static index(params) {
		return request({
			url: '/notification/index',
			method: 'post',
			data: params
		})
	}

	// 保存
	static store(params) {
		return request({
			url: '/notification/store',
			method: 'post',
			data: params
		})
	}
}