<template>
	<div id="tu-notification">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu type="1" />
			</el-col>
			<el-col :span="20">
				<div class="tu-title">
					<div>{{ $t('router.notification') }}</div>
					<div></div>
				</div>
				<div class="warp">
					<el-form ref="form" :model="form" label-width="120px" label-position="top">
						<el-form-item v-for="item, index in form" :key="index" :label="$t(item.langValue)">
					        <el-checkbox border v-model="item.children.email" :label="$t('notify.email')" name="type"></el-checkbox>
					        <el-checkbox border v-model="item.children.mobile" :label="$t('notify.mobile')" name="type"></el-checkbox>
					        <el-checkbox border v-model="item.children.message" :label="$t('notify.message')" name="type"></el-checkbox>
					    </el-form-item>
					    <el-form-item>
							<el-button type="primary" @click="onSubmit">{{ $t('Save') }}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import NotificationApi from '@/api/notification'

export default {
	name: "notification",
	components: {
		SecondMenu
	},
	data() {
		return {
			form: [],
			teacher: [
				{name: "创建预约课程通知", value: 'created', langValue: 'notify.created', children: {email: true, mobile: false, message: true}},
				{name: "审核预约处理通知", value: 'verify', langValue: 'notify.verify', children: {email: true, mobile: false, message: true}},
				{name: "取消预约课程通知", value: 'cancel', langValue: 'notify.cancel', langvalue: 'notify.cancel', langValue: 'notify.cancel', children: {email: true, mobile: false, message: true}},
				{name: "更改预约时间通知", value: 'upd-time', langValue: 'notify.upd-time', langvalue: 'notify.upd-time', langValue: 'notify.upd-time', children: {email: true, mobile: false, message: true}},
				{name: "预约课程上课提醒通知", value: 'remind', langValue: 'notify.remind', children: {email: true, mobile: false, message: true}},
				{name: "预约课程结算通知", value: 'settle', langValue: 'notify.settle', children: {email: true, mobile: false, message: true}},
				{name: "预约课程评价通知", value: 'appraise', langValue: 'notify.appraise', children: {email: true, mobile: false, message: true}},
				{name: "预约课程作业通知", value: 'work-upload', langValue: 'notify.work-upload', children: {email: true, mobile: false, message: true}},
				{name: "平台新消息提醒通知", value: 'new-message', langValue: 'notify.new-message', children: {email: true, mobile: false, message: true}},
			],
			student: [
				{name: "创建预约课程通知", value: 'created', langValue: 'notify.created', children: {email: true, mobile: true, message: true}},
				{name: "审核预约处理通知", value: 'verify', langValue: 'notify.verify', children: {email: true, mobile: true, message: true}},
				{name: "取消预约课程通知", value: 'cancel', langValue: 'notify.cancel', children: {email: true, mobile: true, message: true}},
				{name: "更改预约时间通知", value: 'upd-time', langValue: 'notify.upd-time', children: {email: true, mobile: true, message: true}},
				{name: "预约课程上课提醒通知", value: 'remind', langValue: 'notify.remind', children: {email: true, mobile: true, message: true}},
				{name: "预约课程退款通知", value: 'refund', langValue: 'notify.refund', children: {email: true, mobile: true, message: true}},
				{name: "预约课程评价通知", value: 'appraise', langValue: 'notify.appraise', children: {email: true, mobile: true, message: true}},
				{name: "预约课程自动关闭通知", value: 'close', langValue: 'notify.close', children: {email: true, mobile: true, message: true}},
				{name: "预约课程作业通知", value: 'work-upload', langValue: 'notify.work-upload', children: {email: true, mobile: true, message: true}},
				{name: "预约课程批改通知", value: 'work-correct', langValue: 'notify.work-correct', children: {email: true, mobile: true, message: true}},
				{name: "平台新消息提醒通知", value: 'new-message', langValue: 'notify.new-message', children: {email: true, mobile: true, message: true}},
			]
		}
	},
	computed: {
		...mapGetters([
			'userType'
		])
	},
	created() {
		this.form = this.userType == 2 ? this.teacher : this.student;
		this.getApiInfoData();
	},
	methods: {
		getApiInfoData() {
			NotificationApi.index().then((res) => {
				let r = res.data || false
				let len = r.length;
				if(typeof r == 'object') {
					len = Object.keys(r).length;
				}
				if(!!r && len > 0) {
					for(let i in this.form) {
						this.form[i].children.email = r[this.form[i].value].email == 1 ? true : false
						this.form[i].children.mobile = r[this.form[i].value].mobile == 1 ? true : false
						this.form[i].children.message = r[this.form[i].value].message == 1 ? true : false
					}
				}
			})
		},
		onSubmit() {
			let params = {}
			for(let i in this.form) {
				params[this.form[i].value] = {
					email: this.form[i].children.email == true ? 1 : 0,
					mobile: this.form[i].children.mobile == true ? 1 : 0,
					message: this.form[i].children.message == true ? 1 : 0
				}
			}
			NotificationApi.store({data: params}).then((res) => {
				this.$message.success(this.$t('Save Success'));
			})
		}
	}
}
</script>
<style lang="less">
#tu-notification {
	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.warp {
		padding: 15px;
		.el-form-item {
			margin-bottom: 10px;
			.el-form-item__label {
				padding: 0;
				font-size: initial;
				font-weight: 600;
			}
		}
	}
}
</style>